<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="用户手机号码" prop="moble">
      <el-input v-model="dataForm.moble" placeholder="用户手机号码"></el-input>
    </el-form-item>
    <el-form-item label="1 终端用户 2 网格经理" prop="type">
      <el-input v-model="dataForm.type" placeholder="1 终端用户 2 网格经理"></el-input>
    </el-form-item>
    <el-form-item label="系统用户(网格经理)" prop="sysUserId">
      <el-input v-model="dataForm.sysUserId" placeholder="系统用户(网格经理)"></el-input>
    </el-form-item>
    <el-form-item label="使用机顶盒的终端用户" prop="oliTuserInfoId">
      <el-input v-model="dataForm.oliTuserInfoId" placeholder="使用机顶盒的终端用户"></el-input>
    </el-form-item>
    <el-form-item label="设备sn码" prop="oliTuserInfoSnCode">
      <el-input v-model="dataForm.oliTuserInfoSnCode" placeholder="设备sn码"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/gsmallusersysuser.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        dataForm: {
          id: null,
          moble: '',
          type: '',
          sysUserId: '',
          oliTuserInfoId: '',
          oliTuserInfoSnCode: ''
        },
        dataRule: {
          moble: [
            { required: true, message: '用户手机号码不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '1 终端用户 2 网格经理不能为空', trigger: 'blur' }
          ],
          sysUserId: [
            { required: true, message: '系统用户(网格经理)不能为空', trigger: 'blur' }
          ],
          oliTuserInfoId: [
            { required: true, message: '使用机顶盒的终端用户不能为空', trigger: 'blur' }
          ],
          oliTuserInfoSnCode: [
            { required: true, message: '设备sn码不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.moble = data.data.moble
                this.dataForm.type = data.data.type
                this.dataForm.sysUserId = data.data.sysUserId
                this.dataForm.oliTuserInfoId = data.data.oliTuserInfoId
                this.dataForm.oliTuserInfoSnCode = data.data.oliTuserInfoSnCode
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
			  'moble': this.dataForm.moble,
			  'type': this.dataForm.type,
			  'sysUserId': this.dataForm.sysUserId,
			  'oliTuserInfoId': this.dataForm.oliTuserInfoId,
			  'oliTuserInfoSnCode': this.dataForm.oliTuserInfoSnCode
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
